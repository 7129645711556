import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function NoData({ name }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: {
          md: '300px',
          xs: '150px'
        },
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none'
      }}
    >
      <Typography variant='h3' sx={{ color: '#737373' }}>
        {`${name} - No data found`}
      </Typography>
    </Box>
  );
}

NoData.propTypes = {
  name: PropTypes.string.isRequired
};

export default NoData;