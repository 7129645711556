import * as React from 'react';
import { Typography, Box, styled } from '@mui/material';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import SearchAssetList from '../searchassetlist';

const SearchAssetTabContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .searchReferralBox': {
    width: '100%'
  },
  '& .navTabBox': {
    borderBottom: 1,
    borderColor: 'divider'
  },
  '& .tabBoderBottom': {
    borderBottom: '5px solid #EBAC5B',
    paddingBottom: '12px !important',
    fontSize: '16px !important',
    '& a.MuiTab-root': {
      color: '#383838 !important',
      textTransform: 'capitalize !important'
    },

    '& a.Mui-selected': {
      color: '#1C1C1C !important',
      fontWeight: 'bold !important',
      backgroundColor: '#EBAC5B !important',
      borderRadius: '4px !important'
    },
    '& span.MuiTabs-indicator': {
      background: 'transparent !important'
    }
  }

}));

function SearchAssetTab({ assetsList }) {
  return (
    <SearchAssetTabContainer>
      <Container>
        <Box className='searchReferralBox' p={{ xs: '20px', md: '60px 0' }}>
          <Typography variant='h2' component='h2' sx={{ mb: 2 }}>Search Asset</Typography>
          <Typography variant='headingdividerline' />
          <SearchAssetList assetsList={assetsList} />
        </Box>
      </Container>
    </SearchAssetTabContainer>
  );
}

SearchAssetTab.propTypes = {
  assetsList: PropTypes.arrayOf.isRequired
};

export default SearchAssetTab;
