import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import DownloadIcon from '@mui/icons-material/Download';
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import FilterListIcon from '@mui/icons-material/FilterList';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageListPagination from '../list-pagination';
import ListFilter from '../list-filter';
import ModalPage from '../modal';
import NoData from '../no-data';
import ImageDisplay from '../image-display';
import { getMarketingMaterialFilters, filterMarketingMaterials } from '../../utils/filter-util';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  height: '100%',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const SearchAssetListContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .listGridContainer': {
    padding: '20px 0 !important'
  },
  '& .cardContainer': {
    maxWidth: 396,
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  '& .assetCardMedia': {
    objectFit: 'initial !important'
  },
  '& .assetCardContent1': {
    fontSize: '12px !important',
    color: '#383838 !important'
  },
  '& .assetCardContent2': {
    fontWeight: 'bold !important'
  },
  '& .assetCardContent3': {
    fontSize: '12px !important',
    color: '#898989 !important'
  },
  '& .assetCardAction': {
    position: 'relative !important',
    margin: '5px 0 !important'
  },
  '& .assetCardActionDiv': {
    position: 'absolute !important',
    right: '15px !important'
  },
  '& .assetCardActionButton2': {
    marginLeft: '15px !important',
    verticalAlign: 'middle'
  },
  '& .assetCardActionIcon': {
    fontSize: '16px !important',
    color: '#383838'
  },
  '& .assetCardActionText': {
    fontSize: '12px !important',
    color: '#898989 !important',
    position: 'absolute !important',
    left: '15px !important'
  },
  '& .caption': {
    fontSize: '11px !important',
    color: '#707070 !important'
  },
  '& .paraStyle': {
    fontSize: '15px !important',
    color: '#383838 !important',
    marginTop: '12px !important',
    marginBottom: '40px !important'
  },
  '& .buttonStyle': {
    margin: '5px 10px 5px 0 !important'
  },

  '& .BoxStyle': {
    '& .popupTopCaption': {
      fontSize: '14px !important',
      color: '#272727 !important'
    },
    '& .popupHeader': {
      fontSize: '32px !important',
      color: '#383838 !important',
      fontWeight: 'bold !important'
    },
    '& .popupSubHeading': {
      fontSize: '14px !important',
      color: '#272727 !important',
      fontWeight: 'bold !important'
    },
    '& .popupBottomButton': {
      marginTop: '20px !important'
    },
    '& .iconStyle': {
      fontSize: '24px !important',
      verticalAlign: 'middle !important',
      float: 'right !important',
      cursor: 'pointer !important'
    }
  }
}));

const ListFilters = styled(Box)(() => ({
  '& .displayBlock': {
    // display: 'block',
    position: 'absolute',
    backgroundColor: '#fff',
    left: '5px',
    width: '100%',
    top: '72px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  '& .displayNone': {
    display: 'none'
  },
  '& .filterPopover': {
    alignItems: 'CENTER',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  }
}));

const ToggleButtonCmp = styled(ToggleButton)(() => ({
  // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
  fontFamily: '"Source Sans Pro", sans-serif',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&.Mui-selected': {
    background: '#E95525 !important',
    color: '#000000'
  },
  '&.Mui-selected::after': {
    content: '""',
    height: 0,
    width: 0,
    display: 'block',
    borderWidth: '10px 10px 10px 0px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    position: 'absolute',
    top: '32px',
    borderColor: 'transparent transparent #EBAC5B transparent',
    borderStyle: 'solid',
    transform: 'rotate(0deg)',
    left: '40%'
  }
}));

function SearchAssetList({ assetsList }) {
  const matches = useMediaQuery('(min-width:767px)');

  /** Essential for Responsive */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  /** Essential for Responsive Ends */

  const [assets, setAssetsList] = React.useState(assetsList || []);

  const [pagePaginationText, setPagePaginationText] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [currentpageno, setCurrentpageno] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(assetsList?.length ? Math.ceil(assetsList.length / rowsPerPage) : []);

  const [openPopup, setOpen] = React.useState(false);
  const [marketingMaterialData, setMarketingMaterialData] = React.useState(null);

  const handleOpen = marketingMaterial => {
    setMarketingMaterialData(marketingMaterial);
    setOpen(true);
  };

  const handleCloseMarketing = () => {
    setMarketingMaterialData(null);
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  /** Filter Functionality Starts ------------------ */

  const [selectedFilters, setSelectedFilters] = React.useState([]);

  const [allButtonSelected, setAllButtonSelected] = React.useState(true);

  const onAllButtonChange = () => {
    // if selected, unselected -> show all, reset page, reset all filters
    const updatedSelectedFilters = selectedFilters.map(filter => ({
      label: filter.label,
      options: []
    }));

    setSelectedFilters(updatedSelectedFilters);

    // Set new Program List
    setAssetsList(filterMarketingMaterials(assets, updatedSelectedFilters).slice(0, rowsPerPage));

    // Reset Page No.
    setCurrentpageno(1);
  };

  const onFilterChange = (label, selectedItems) => {
    const updatedSelectedFilters = [...selectedFilters];
    const updatedFilterIndex = selectedFilters.findIndex(filter => filter.label === label);
    if (updatedFilterIndex === -1) {
      updatedSelectedFilters.push({
        label,
        options: selectedItems
      });
    } else {
      updatedSelectedFilters[updatedFilterIndex].options = selectedItems;
    }

    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);

    setAllButtonSelected(false);
  };

  const removeFilter = (label, optionToBeRemoved) => {
    const updatedSelectedFilters = [...selectedFilters];
    const filterToBeUpdated = updatedSelectedFilters.find(filter => filter.label === label);
    if (filterToBeUpdated) {
      filterToBeUpdated.options = filterToBeUpdated.options.filter(option => option !== optionToBeRemoved);
    }
    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);
  };

  const checkForSelectedOptions = () => {
    const selectedOptions = selectedFilters.map(filter => filter.options).flat(2);
    if (selectedOptions.length === 0) {
      return true;
    }
    return false;
  };

  /** Filter Functionality Ends ------------------ */

  const rowsPerPageCallback = updatedRowsPerPage => {
    setRowsPerPage(updatedRowsPerPage);
    setCurrentpageno(1);
  };

  const updateCurrentpageno = (_, newPageNo) => {
    setCurrentpageno(newPageNo);
  };

  const setPagePagination = total => {
    let start = null;
    let end = null;
    const of = total;

    // Start number logic
    if (of === 0) {
      start = 0;
      end = 0;
    }

    if (currentpageno === 1 && of > 0) {
      start = 1;
      if (total < rowsPerPage) {
        end = total;
      } else {
        end = rowsPerPage;
      }
    }

    if (currentpageno > 1) {
      start = rowsPerPage * (currentpageno - 1) + 1;
      end = rowsPerPage * currentpageno;
      if (total < end) {
        end = total;
      }
    }

    setPagePaginationText(`Showing ${start}-${end} of ${of}`);
  };

  React.useEffect(() => {
    if (checkForSelectedOptions()) {
      setAllButtonSelected(true);
    }

    const filteredAssets = filterMarketingMaterials(assetsList, selectedFilters);

    // Set new Programs list by setting index start and end
    setAssetsList(
      filteredAssets.slice(
        currentpageno === 1 ? 0 : (currentpageno - 1) * rowsPerPage,
        rowsPerPage * currentpageno
      )
    );

    // Set Total Pages Count
    setTotalPages(Math.ceil(filteredAssets.length / rowsPerPage));
    setPagePagination(filteredAssets.length);
  }, [selectedFilters, rowsPerPage, currentpageno]);

  return (
    <SearchAssetListContainer>
      {
        openPopup && marketingMaterialData && <ModalPage onChange={handleCloseMarketing} open={openPopup} marketingMaterial={marketingMaterialData} tags={marketingMaterialData?.metadata?.tags || []} />
      }

      {/* Filter Section */}
      <Grid container spacing={1} sx={{ pt: 4 }}>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} justifyContent={{ xs: 'space-between', sm: 'flex-start' }}>
            <Box>
              <ToggleButtonCmp
                sx={{ display: 'inline-block', mr: 2 }}
                size='small'
                selected={allButtonSelected}
                onChange={() => {
                  setAllButtonSelected(!allButtonSelected);
                  onAllButtonChange();
                }}
              >
                All
              </ToggleButtonCmp>
            </Box>
            <Box>
              <ListFilters>
                {matches ? (
                  <ListFilter filters={getMarketingMaterialFilters(assetsList)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                ) : (
                  <div>
                    <IconButton
                      size='large'
                      edge='start'
                      color='inherit'
                      aria-label='menu'
                      sx={{ mr: 2 }}
                      aria-describedby={id}
                      variant='contained'
                      onClick={handleClick}
                    >
                      <FilterListIcon />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}

                    >
                      <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        p={2}
                      >
                        <ListFilter filters={getMarketingMaterialFilters(assetsList)} selectedFilters={selectedFilters} onChange={onFilterChange} />
                      </Grid>
                    </Popover>
                  </div>
                )}
              </ListFilters>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: '#EBAC5B', height: 5, mt: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mt: 1 }}>
            <Typography
              variant='cardcategory'
              sx={{
                mr: 1,
                display: {
                  xs: 'block',
                  md: 'inline-block'
                }
              }}
            >
              {pagePaginationText}
            </Typography>
            {
              selectedFilters
                .map(filter => filter.options
                  .map(option => (
                    <Chip
                      sx={{ mb: 0 }}
                      size='small'
                      key={option}
                      label={option}
                      variant='outlined'
                      onDelete={() => removeFilter(filter.label, option)}
                    />
                  )))
            }
          </Box>
        </Grid>
      </Grid>

      {/* Marketing Materials Listing */}
      <Grid container spacing={2} className='listGridContainer'>

        {/* No data... */}
        {assets && assets.length === 0 && (
          <NoData name='Marketing Materials' />
        )}

        {/* MM List */}
        {assets && assets.length !== 0 && assets?.map(marketingMaterial => (
          <Grid item xs={12} sm={4}>
            <Item>
              <Card className='cardContainer' onClick={() => handleOpen(marketingMaterial)}>
                <Box>
                  <CardContent>
                    <Typography variant='body2' className='assetCardContent1'>{marketingMaterial.category}</Typography>
                    <Typography gutterBottom variant='body1' component='div' className='assetCardContent2'>
                      {marketingMaterial.title}
                    </Typography>
                    {marketingMaterial.shortTitle && (
                      <Typography gutterBottom variant='body1' component='div' className='assetCardContent1'>
                        <b>{marketingMaterial.shortTitle}</b>
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions className='assetCardAction'>
                    <Typography variant='body2' className='assetCardContent1'>
                      {marketingMaterial.updatedAt}
                    </Typography>

                    <Typography component='div' className='assetCardActionDiv'>
                      {marketingMaterial?.download?.file?.url
                        && (
                          <Link href={marketingMaterial.download.file.url}>
                            <DownloadIcon size='small' variant='contained' className='assetCardActionIcon' />
                          </Link>
                        )}
                    </Typography>
                  </CardActions>
                </Box>
                {
                  marketingMaterial?.thumbnailImage && (
                    <ImageDisplay
                      data={marketingMaterial?.thumbnailImage}
                    />
                  )
                }
              </Card>
            </Item>
          </Grid>
        ))}

        {/* Pagination */}
        {assets && assets.length !== 0 && (
          <Grid container item justifyContent='flex-start' alignItems='center'>
            <Grid xs={12} sm={12} md={4}>
              <PageListPagination totalRows={assets.length} rowsPerPageCallback={rowsPerPageCallback} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} alignItems='center' justifyContent='center' container>
              <Pagination
                count={totalPages}
                page={currentpageno}
                onChange={updateCurrentpageno}
                variant='outlined'
              />
            </Grid>
            <Grid xs={0} sm={4} />
          </Grid>
        )}
      </Grid>
    </SearchAssetListContainer>
  );
}

SearchAssetList.propTypes = {
  assetsList: PropTypes.arrayOf.isRequired
};

export default SearchAssetList;
