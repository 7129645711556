/* eslint-disable no-console */
import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ImageDisplay from '../image-display';

const style = theme => ({
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: '1px 2px 11px #00000029',
  p: 4,
  borderRadius: '10px',
  padding: '15px',
  height: '70vh',
  overflow: 'auto'
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const ModalContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  '& .listGridContainer': {
    padding: '20px 0 !important'
  },
  '& .cardContainer': {
    maxWidth: 396
  },
  '& .assetCardMedia': {
    objectFit: 'initial !important'
  },
  '& .assetCardContent1': {
    fontSize: '12px !important',
    color: '#383838 !important'
  },
  '& .assetCardContent2': {
    fontWeight: 'bold !important'
  },
  '& .assetCardContent3': {
    fontSize: '12px !important',
    color: '#898989 !important'
  },
  '& .assetCardAction': {
    position: 'relative !important',
    padding: '19px 15px 19px 15px !important',
    margin: '15px 0 !important'
  },
  '& .assetCardActionDiv': {
    position: 'absolute !important',
    left: '15px !important'
  },
  '& .assetCardActionButton1': {
    width: '147px',
    height: '20px',
    marginLeft: '15px !important',
    verticalAlign: 'middle'
  },
  '& .assetCardActionButton2': {

    marginLeft: '15px !important',
    verticalAlign: 'middle'
  },
  '& .assetCardActionFont16': {
    fontSize: '16px !important'
  },
  '& .assetCardActionText': {
    fontSize: '12px !important',
    color: '#898989 !important',
    position: 'absolute !important',
    right: '15px !important'
  },
  '& .caption': {
    fontSize: '11px !important',
    color: '#707070 !important'

  },
  '& .paraStyle': {
    fontSize: '15px !important',
    color: '#383838 !important',
    marginTop: '12px !important',
    marginBottom: '40px !important'
  },
  '& .buttonStyle': {
    margin: '5px 10px 5px 0 !important'
  },

  '& .BoxStyle': {
    '& .popupTopCaption': {
      fontSize: '14px !important',
      color: '#272727 !important'
    },
    '& .popupHeader': {
      fontSize: '32px !important',
      color: '#383838 !important',
      fontWeight: 'bold !important'
    },
    '& .popupSubHeading': {
      fontSize: '14px !important',
      color: '#272727 !important',
      fontWeight: 'bold !important'
    },
    '& .popupBottomButton': {
      marginTop: '20px !important'
    },
    '& .iconStyle': {
      fontSize: '24px !important',
      verticalAlign: 'middle !important',
      float: 'right !important',
      cursor: 'pointer !important'
    },
    backDrop: {
      background: 'rgba(255,255,255,0.2)'
    }
  }
}));

function ModalPage({ onChange, open, marketingMaterial, tags }) {
  const [isOpen, setOpen] = React.useState(open);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onChange(false);
  };

  return (
    <ModalContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box className='BoxStyle'>
            <CloseIcon className='iconStyle' open={isOpen} onClick={handleClose} sx={{ float: 'right' }} />
            <Grid container spacing={2} className='ContainerStyle'>

              <Grid
                xs={12}
                sm={6}
                container
                sx={{ background: '#F5F5F5' }}
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Box p={2}>
                  <ImageDisplay data={marketingMaterial?.image} />
                  {/* <img width='100%' height='100%' src={marketingMaterial?.image?.gatsbyImageData?.images?.fallback?.src} alt='aarplogo' className='aboutImage' /> */}
                </Box>
                {marketingMaterial?.imageDescription?.imageDescription && (
                  <Box sx={{ backgroundColor: '#fff', padding: '10px' }}>
                    <Typography className='caption'>{marketingMaterial?.imageDescription?.imageDescription}</Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Typography variant='cardcategory' className='popupTopCaption'>{marketingMaterial.category}</Typography>

                  <Typography gutterBottom variant='h2' component='div' className='popupHeader'>
                    {marketingMaterial.title}
                  </Typography>
                  {marketingMaterial.shortTitle && (
                    <Typography gutterBottom variant='h4' component='div' className='popupHeader'>
                      {marketingMaterial.shortTitle}
                    </Typography>
                  )}
                  <Typography variant='body2' color='text.secondary' paddingBottom='10px' className='popupTopCaption'>
                    {marketingMaterial.updatedAt}
                  </Typography>
                  <Typography variant='body2' paddingBottom='30px' className='paraStyle'>{marketingMaterial.description?.description}</Typography>
                  <Typography component='div' className='div' paddingBottom='20px'>
                    {
                      tags.map(tag => (
                        <Link style={{ textDecoration: 'none' }} to={`/tag/${tag.name.toLowerCase().split(' ').join('-')}`} className='tag'>
                          <Chip label={tag.name} variant='outlined' key={tag.id} />
                        </Link>
                      ))
                    }
                  </Typography>
                  <Typography component='div' className='popupBottomButton'>
                    {marketingMaterial?.download?.file?.url && marketingMaterial?.download?.file?.fileName && (
                      <Link style={{ textDecoration: 'none' }} href={marketingMaterial.download.file.url}>
                        <Button
                          size='small'
                          color='primary'
                          variant='contained'
                          className='assetCardActionButton1'
                        >
                          {marketingMaterial?.download?.file?.fileName}
                        </Button>
                      </Link>
                    )}
                    {/* <Typography variant='button' size='small' className='assetCardActionButton2'><FavoriteBorderOutlinedIcon className='assetCardActionFont16' /></Typography>
                    <Typography variant='button' size='small' className='assetCardActionButton2'><ShareIcon className='assetCardActionFont16' /></Typography> */}
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </ModalContainer>
  );
}

ModalPage.propTypes = {
  marketingMaterial: PropTypes.node,
  open: PropTypes.bool,
  onChange: PropTypes.node,
  tags: PropTypes.node
};

ModalPage.defaultProps = {
  marketingMaterial: null,
  open: null,
  onChange: null,
  tags: null
};

export default ModalPage;
