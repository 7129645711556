import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Layout from '../../../components/layout/layout';
import ProgramHero from '../../../components/program-hero';
import SearchAssetTab from '../../../components/searchassettab';

function ProgramMarketingMaterial({ data, location }) {
  const marketingMaterialData = data.contentfulPageProgram;
  const programHeroData = {
    title: marketingMaterialData?.title,
    category: marketingMaterialData?.category,
    shortTitle: marketingMaterialData?.programShortTitle,
    socialLinks: marketingMaterialData?.socialLinks,
    website: marketingMaterialData?.website,
    shortDescription: marketingMaterialData?.programShortDescription?.programShortDescription,
    embeddedVideo: marketingMaterialData.programVideo?.embeddedUrl?.embeddedUrl,
    programImage: marketingMaterialData?.programImage,
    thumbnailImage: marketingMaterialData?.thumbnailImage,
    updatedAt: marketingMaterialData?.updatedAt,
    buttons: marketingMaterialData?.buttons
  };

  React.useEffect(() => {
    if (!marketingMaterialData?.marketingMaterial?.length) {
      navigate(location.pathname.replace('/marketing-materials', ''));
    }
  }, []);
  if (marketingMaterialData?.marketingMaterial?.length) { marketingMaterialData.marketingMaterial.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)); }

  return (
    <Layout
      legalFootNote={marketingMaterialData?.legalFootNote?.legalFootNote}
      location={location}
      pageName={programHeroData.shortTitle}
      isMarketingMaterials={!!marketingMaterialData?.marketingMaterial?.length}
    >
      <ProgramHero data={programHeroData} />
      <SearchAssetTab assetsList={marketingMaterialData.marketingMaterial || []} />
    </Layout>
  );
}

export const query = graphql`
  query ProgramDetailMarketingMaterial($slug: String!) {
    contentfulPageProgram(slug: {eq: $slug}) {
      category
      programShortTitle
      programShortDescription {
        programShortDescription
      }
      programTitle
      programDescription {
        raw
      }
      updatedAt(formatString: "MMMM DD, YYYY")
      programVideo {
        embeddedUrl {
          embeddedUrl
        }
      }
      programImage {
        file {
          url
          fileName
        }
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          width: 730
          quality: 100
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
      website {
        ... on ContentfulCompExternalLink {
          id
          openInANewTab
          title
          url {
            url
          }
        }
      }
      socialLinks {
        facebookUrl
        instagramUrl
        linkedinUrl
        twitterUrl
        youtubeUrl
      }
      legalFootNote {
        legalFootNote
      }
      buttons {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageUtility {
                id
                entryTitle
                contentful_id
                type
                slug
              }
            }
          }
          ... on ContentfulCompExternalLink {
            id
            openInANewTab
            title
            url {
              url
            }
          }
        }
      }
      marketingMaterial {
        ...contentfulMarketingMaterials
      }
    }
  }

  fragment contentfulMarketingMaterials on ContentfulCompMarketingMaterial {
    metadata {
      tags {
        id
        name
        contentful_id
      }
    }
    image {
      file {
        url
        fileName
      }
      gatsbyImageData(
        height: 380
        jpegProgressive: true
        formats: WEBP
        resizingBehavior: SCALE
        placeholder: BLURRED
      )
    }
    category
    shortTitle
    brand: shortTitle
    title
    description {
      description
    }
    imageDescription {
      imageDescription
    }
    download {
      file {
        fileName
        url
      }
    }
    thumbnailImage {
      gatsbyImageData(
        aspectRatio: 1.78
        jpegProgressive: true
        formats: WEBP
        placeholder: BLURRED
      )
    }
    updatedAt(formatString: "MMMM DD, YYYY")
  }

`;

ProgramMarketingMaterial.propTypes = {
  location: PropTypes.shape.isRequired
};

export default ProgramMarketingMaterial;
